
@font-face {
  font-family: "Helvetic Neue";
  /*a name to be used later*/
  src: url(/fonts/HelveticaNeueLTStdLt.otf?06137036ee2d59941c9bda383866b943);
  /*URL to font*/
  font-family: "Helvetic Neue Bold";
  /*a name to be used later*/
  src: url(/fonts/HelveticaNeueLTStdBd.otf?9ac7e2ff6b9792291e65269b982efa35);
  /*URL to font*/
}

.select2-selection__rendered {
  line-height: 31px !important;
}

.select2-container .select2-selection--single {
  height: 35px !important;
}

.select2-selection__arrow {
  height: 34px !important;
}

body {
  font-family: helvetica;
  background-color: white;
  color: $letter-color;
}

.inmoTb {
  height: 25px;
  width: auto;
}

.mediaProyect {
  height: 200px;
  width: auto;
}

.test {
  margin-left: -25px;
  margin-top: 60px;
}

.mediaProyect2 {
  height: 100px;
  width: auto;
}

.page-title {
  color: #5A5A5A;
  font-weight: bolder;
  font-size: 45px;
}
.title-financiamiento{
  font-size: 40px;
}
.invert-title {
  font-size: 16px;
}

.page-body {
  color: #5A5A5A;
  font-size: 15px;
}

.page-body2 {
  color: #969696;

  font-size: 18px;
}

.page-text {
  color: #5A5A5A;
}

.card-main {
  background-color: $main-color;
  color: white;
}

.card-alt {
  background-color: #BCD7D1;
  color: white;
}

.card-title-alt {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  margin-top: 10px;
}

.card-text-alt {
  font-family: "Roboto", sans-serif;
  margin-top: -10px;
  width: 110%;;
}

.main-color {
  color: $main-color;
  border-color: $main-color
}

.btn-main-color {
  font-family: "Overpass", sans-serif;
  font-size: 16px;
  letter-spacing: -0.03px;
  color: #ffffff;
  background-color: $main-color;
  height: 60px;
  padding: 0px 35px 0px 35px;
  border-radius: 30px;
  border-width: 0px;
}

.second-color {
  color: #bcd7d1;
  border-color: #bcd7d1;
}

.td-none:hover {
  text-decoration: none;
}

.mt4 {
  margin-top: 4px;
}

.bg-main-color {
  background-color: $main-color;
  border-color: $main-color;
}

.bg-sec-color {
  background-color: #BCD7D1;
  border-color: #BCD7D1;
}

/* NAV BAR */

.navbar-bg {
  background-color: transparent;
}

.nav-bar-logo, .nav-bar-logo-negro {
  height: 50px;
}

.nav-bar-logo-negro {
  display: none;
}

.newProfileBtn {
  border: 1px solid $main-color;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin: 0px 5px 0px 5px;
  font-size: 15px;
}

.tipoImg {
  height: 200px;
}

.nbnlg-btn {
  display: none;
}

.logoutLink:hover {
  text-decoration: none;
}

.navBar-btn {
  margin-right: 5px;
  margin-top: -30px;
  border-color: $main-color;
  border-radius: 6px;
}

.navBar-btn:hover,
.navBar-btn:focus,
.navBar-btn:active {
  background-color: #ca3687;
  border-color: #ca3687;
  box-shadow: 0 0 0 0.2rem rgba(143, 71, 137, 0.5);
}

.navBar-btn-outline {
  margin-right: 0;
}

.navBar-btn-outline:hover {
  color: white;
}

.navBar-btn-outline:focus,
.navBar-btn-outline:active {
  background-color: #47798F;
  border-color: #47798F;
  box-shadow: 0 0 0 0.2rem rgba(71, 121, 143, 0.5);
}

.general-btn-outline:hover {
  /*color: white;
  background-color: #47798F;*/
}

.general-btn-outline:focus {
  /*background-color: #47798F;
  border-color: #47798F;
  box-shadow: 0 0 0 0.2rem rgba(71, 121, 143, 0.5);*/
}

.general-btn-outline:active {
  /*background-color: #47798F;
  border-color: #47798F;
  box-shadow: 0 0 0 0.2rem rgba(71, 121, 143, 0.5);*/
}

.general-btn-bluishgray {
  /*color: white;
  background-color: #BCD7D1;
  font-size: 10px;
  border-color: #BCD7D1;
  border-radius: 15px;*/
}

.general-btn-bluishgray:hover {
  /*color: white;
  background-color: #a4beb9;
  border-color: #a4beb9;*/
}

.general-btn-gray {
  /*color: white;
  background-color: #D0D0D0;
  border-color: #D0D0D0;*/
}


.general-btn-outline2:hover {
  /*color: white;
  background-color: #BCD7D1;*/
}

.general-btn-outline2:focus {
  /*background-color: #BCD7D1;
  border-color: #BCD7D1;
  box-shadow: 0 0 0 0.2rem rgba(188, 215, 209, 0.5);*/
}

.general-btn-outline2:active {
  /*background-color: #BCD7D1;
  border-color: #BCD7D1;
  box-shadow: 0 0 0 0.2rem rgba(188, 215, 209, 0.5);*/
}

.navBar-btn-icon {
  margin-left: 4px;
}

.nav-link-text {
  font-size: 12px;
  color: white;
}
.zp-blue{
  color: $new-blue-color !important;
}



/* Login Modal */

.login-modal-container {
  margin: 0 !important;
}

.cotizador-modal-container {
  margin: 40px;
}

.login-modal-tab {
  color: #5A5A5A;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.login-modal-tab.active {
  color: $new-blue-color;
}

.modal-body-cont {
  padding: 0;
}

.login-modal-tab:hover {
  color: $new-blue-color;
  text-decoration: none;
}

.login-modal-container input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
}

.login-modal-fp {
  font-size: 12px;
}

.login-modal-link {
  font-size: 12px;
  color: #5A5A5A;
}

.login-modal-link.active {
  color: #62A4C0;
}

.login-modal-link:hover {
  color: #62A4C0;
  text-decoration: none;
}

.login-modal-container h6 {
  font-size: 14px;
}

.login-modal-container .sm-login-icons {
  font-size: 34px;
  margin-right: 10px;
}

.login-modal-container .sm-login-icons:hover {
  text-decoration: none;
}

.login-modal-container .insta {
  color: #AE62C0;
}

.login-modal-container .fb {
  color: #3675AF;
}

.login-modal-container .lnkd {
  color: #3EB0E0;
}

/* Currency Bar */

.l-currency-Bar {
  background-color: #BCD7D1;
  color: white;
  box-shadow: inset 0 0 5px #00000014;
  padding: 6px;
  width: 100%;
}

.r-currency-Bar {
  box-shadow: inset 0 0 5px #00000014;
  padding: 6px;
  height: 30px;
  display: flex;
}

.newsG {
  position: absolute;
  transition: opacity 0.5s ease-in;
}

.newsG + .newsG {
  opacity: 0;
}

.onlyDesk {
  display: none;
}

.onlyTablet {
  display: none;
}

.lcbar-item {
  padding-right: 15px;
  padding-left: 15px;
}

.lcbar-item-rbordered {
  border-right: 1px solid #ffffff;
}

.rcbar-item {
  padding-right: 11px;
  padding-left: 11px;
}

.hide-mobile {
  display: none;
}

.hide-tablet {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #BCD7D1;
  color: white;
}

/* Main Carrousel */

.slider-cont {
  min-height: 600px;
  background-image: url(/storage/imgs/banner/slider-bg.png?);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.slider-cont-invertpage {
  background-image:linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(/storage/imgs/banner/invert-banner.png);

}

.slider-cont-postindex {
  background-image:linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(/storage/imgs/banner/banner-postindex.png);
}

.slider-cont-financia {
  background-image:linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(/storage/imgs/banner/banner-financia.png);
}

.slider-cont-pro {
  min-height: 420px!important;
  background-image:linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(/storage/imgs/banner/slider-bg-pro.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.slider-cont-cotizacion {
  min-height: 100px;
  height: 300px;
  width: auto;
}

.respaldo-slider-cont {
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/storage/imgs/banner/respaldoBannerPlaceholder1.png);
}

.cotizador-img {
  height: auto;
  width: 300px;
}

.post-slider-cont {
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/storage/imgs/banner/postBannerPlaceholder1.png);
}

.yg-btn {
  color: #BCD7D1;
  border-color: #BCD7D1;
}

.banner-cont {
  min-height: 300px;
  display: flex;
  align-items: center;
  background-position: bottom;
}

.banner-cont-500 {
  min-height: 600px;
}

.banner-cont-350 {
  min-height: 350px;
  align-items: flex-end;
  padding-bottom: 50px;
}

.banner-cont-300 {
  min-height: 275px;
}

.color-disable {
  color: #D0D0D0;
}

.b-border {
  border-bottom: 1px solid #62A4C0;
  margin-right: 50%;
}

.b-border2 {
  border-bottom: 1px solid #62A4C0;
}

.bg-disable {
  border-color: #D0D0D0;
  background-color: #D0D0D0;
}

.invp-super-title {
  color: #F846A8;
  font-size: 14px;
}

.invp-title {
  color: #BCD7D1;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  font-size: 40px;
}

.proyect-info {
  background-image:linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(/storage/imgs/banner/banner.jpeg);
  background-position: bottom;
}

.banner-lpanel {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.banner-rpanel {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  margin-bottom: 50px;
}

.super-title-banner {
  font-size: 20px;
}

.banner-title {
  font-family: "Helvetica";
  font-weight: bolder;
  font-size: 50px;
}

.banner-rtitle {
  font-family: "Helvetica";
  font-weight: bolder;
  font-size: 28px;
}

.banner-pretitle {
  font-size: 21px;
  font-weight: normal;
}

.slider-lpanel {
  text-align: center;
  margin-bottom: 40px;
}

.carrousel-st {
  color: #F846A8;
  font-family: "Helvetica";
}

.carrousel-st-white {
  color: white;
  margin-top: 100px;
}

.carrousel-title {
  color: #5A5A5A;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.carrousel-title-white {
  color: white;
  font-weight: bolder;
  font-size: 45px;
}

.carrousel-text {
  color: #5A5A5A;
  font-family: "Roboto", sans-serif;
}

.carrousel-text-white {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.slider-rpanel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.slider-rcardpanel {
  width: 100%;
  padding: 1.7rem 1.7rem;
}

.mainSearchPanel h1 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.mainSearchPanel label {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.general-btn {
  margin: 0;
  height: 2rem;
  font-size: 12px;
  padding: 0px 55px;
  font-weight: bold;
}

.general-btn-sec {
  /*border-color: #BCD7D1;
  border-radius: 6px;
  color: white;
  width: 60%;*/
}

.btn-white {
  border-color: white;
  background-color: white;
  color: #888888;
}

.general-btn:hover {
  /*background-color: #ca3687;
  border-color: #ca3687;
  color: white;*/
}

.general-tab:hover {
  background-color: #BCD7D1;
  border-color: #BCD7D1;
  color: white;
}

.general-btn-sec:hover {
  /*background-color: #BCD7D1;
  border-color: #BCD7D1;
  color: white;*/
}

.form-control {
  border: 1px solid #D0D0D0;
}

.custom-range::-webkit-slider-thumb {
  background: $main-color;
}

/* Logo Bar */

.logo-bar-cont {
  background-color: white;

}

.logo-bar-item {
  padding: 1rem 0.5rem;
  width: 9rem;
}

/* Sectioins */

.section-cont {
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

.noLink {
  color: inherit;
}

.noLink:hover {
  color: inherit;
  text-decoration: none;
}

.section-title-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: -30px;;
}

.mini-title {
  color: #5A5A5A;
  font-family: "Helvetic Neue Bold";
}

.test {
  height: inherit;
}

.divider {
  border-top: 1.5px solid #70707064;
  margin-top: 0px;
  margin-bottom: 50px;
}

.main-form {
  background-color: #F0F0F0;
  border: 0;
  border-radius: 6px;
}

.main-form-btn {
  width: 100%;
  background-color: white;
  color: $main-color;
  border-color: $main-color;
  border-radius: 6px;
  font-family: "Helvetic Neue Bold";
  margin-top: 20px;
}

.main-form-btn:hover {
  background-color: #ca3687;
  border-color: #ca3687;
  color: white;
}

.section-title-cont .section-title {
  color: #5A5A5A;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.section-title-cont .section-body {
  color: #5A5A5A;
  font-family: "Roboto", sans-serif;
}

.pb-80 {
  padding-bottom: 80px;
}

.vi-icon {
  display: flex;
  justify-content: center;
}

.vi-icon img {
  width: 40%;
}

.vi-body {
  text-align: center;
  margin-top: 30px;
  margin-bottom: auto;
}

.ventajas-item {
  margin-bottom: 25px;
}

.ventajas-item h6 {
  color: #5A5A5A;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 1;
}

.ventajas-item p {
  color: #5A5A5A;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1;
}

.section-lpanel {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 25px;
  margin-bottom: 40px;
}

.section-rpanel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-rcardpanel {
  width: 100%;
}

.slider-cont-cards {
  padding-left: 2px;
  padding-right: 2px;
}

.singlePanelSlider {
  height: 400px;
  border-radius: 6px;
}

.cust-badges {
  padding: 5px 17px;
  float: right;
  margin-bottom: 5px;
  margin-left: 5px;
}

.dark-blue-bg {
  background-color: #1D3B56;
}

.slide-body {
  position: absolute;
  bottom: 51px;
  left: 15px;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.slide-body small {
  font-size: 16px;
  line-height: 0.8;
}

.slide-body h6 {
  font-size: 23px;
  line-height: 0.8;
}

.slide-body h4 {
  font-size: 32px;
  line-height: 0.8;
}

.control {
  height: 60px;
  width: 60px;
  //background-color: white;
  //border-radius: 50%;
  //font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  //box-shadow: 2px 2px 5px #00000015;
}

.owl-nav {
  position: absolute;
  left: 50%;
  bottom: 5%;
}

.control-left {
  position: absolute;
  bottom: -30px;
  left: 190px;
}

.control-right {
  position: absolute;
  bottom: -30px;
  right: 190px;
}

.panel-icon {
  height: 150px;
  margin: 5px 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pi-size {
  font-size: 40px;
  margin-bottom: 5px;
}

.panel-icon h4 {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  font-size: 24px;
}

.panel-icon-inver {
  background-image: url(/storage/imgs/banner/panel-inversion.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-vivir {
  background-image: url(/storage/imgs/banner/panel-vivir.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-proyectos {
  background-image: url(/storage/imgs/banner/panel-proyectos.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-sustentables {
  background-image: url(/storage/imgs/banner/panel-sustentables.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-unidades {
  background-image: url(/storage/imgs/banner/panel-unidades.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-ventas {
  background-image: url(/storage/imgs/banner/panel-ventas.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.panel-icon-entrega {
  background-image: url(/storage/imgs/banner/panel-entrega.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.section-slide1 {
  background-image: url(/storage/imgs/otros/san_bernardo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.section-slide2 {
  background-image: url(/storage/imgs/otros/la_florida_1200.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.hide-slide {
  display: none;
}

/* Footer/ */

.footer p {
  font-size: 12px;
  width: 80%;
}

/* Left Bar */

.leftbar-cont {
  background-color: #f5f5f5;
  padding: 80px 40px 40px 40px;
}

.leftbar-cont2 {
  background-color: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 40px;
}

.leftbar-nav-link {
  color: black;
}

.leftbar-nav-link.active {
  color: $main-color !important;
  background-color: white !important;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 0%;
  padding-left: 35px;
  width: 300px;
  margin-left: -10px;
  padding-left: 35px;
}

.select2-container--default .select2-selection--single::after {
  position: absolute;
  right: 5px;
  top: 35%;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\F078";
  font-size: 0.7rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-style: none !important;
}

/* Main body Right Panel */

.rightpanel {
  padding-top: 50px;
  padding-bottom: 50px;
}

.rightpanel2 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.minibar {
  margin-bottom: 35px;
}

.post-img {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 500px;
}

.minibar-filter-btn-hidden-sm {
  display: none;
}

.rightpanel .page-display {
  margin-top: 20px;
  text-align: center;
}

.item-main-grid {
  border-radius: 6px;
}

.item-main-grid-img2 {
  height: 200px;
  width: auto;
}

.grid-item-link {
  color: #212529;
}

.grid-item-link:hover {
  color: #212529;
  text-decoration: none;
}

.item-main-list-img {
  height: 325px;
  width: 100%;
}

.item-main-grid .badge-cont {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 100%;
  /* font-family: 'Helvetic Neue Bold';
  font-weight: bolder; */
}

.like-icon {
  position: absolute;
  top: 20px;
  left: 15px;
  color: #D0D0D0;
}

.like-icon:hover {
  color: $main-color;
}

.like-icon2 {
  position: absolute;
  top: 15px;
  left: 30px;
  color: #D0D0D0;
}

.like-icon2:hover {
  color: $main-color;
}

.badge-cont2 {
  position: absolute;
  top: 20px;
  right: 25px;
}

.list-badge-cont {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 100%;
}

.item-main-grid .grid-title {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 0;
}

.item-main-grid .subtitle-mapmarker {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.list-title {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  margin-bottom: 0;
}

.list-subtitle-mapmarker {
  font-family: "Roboto", sans-serif;
}

.item-main-grid .bed-square-room {
  font-size: 18px;
}

.item-main-grid .bed-square-room .gm-icon {
  color: #D0D0D0;
}

.star-rating {
  color: #FFC107;
  font-size: 16px;
}

.star-rating-empty {
  color: #70707060;
  font-size: 16px;
}

.rating-anex {
  font-size: 18px;
  font-weight: bold;
}

.list-rating-anex {
  font-weight: bold;
}

.leftbar-cont .lb-form {
  font-size: 16px;
}

.leftbar-cont .lb-form .form-group {
  margin-bottom: 50px;
}

.leftbar-cont .lb-form button {
  width: 100%;
  font-size: 16px;
}

.rightpanel p {
  font-size: 12px;
  padding-top: 10px;
  text-align: right;
}

.mainbody {
  padding-left: 0px;
  padding-right: 20px;
  margin-bottom: 40px;
}

.grid-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-link {
  color: #62A4C0;
}

.page-item.active .page-link {
  background-color: #ca3687;
  border-color: #ca3687;
}

/* Leftside Filter Panel */

.unidad-filter-cont {
  margin-top: 50px;
}

.uf-cont-title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
}

.uf-room-item {
  font-size: 14px;
}

.mm-filter-cont {
  margin-top: 20px;
}

.mm-filter-input {
  font-size: 12px;
}

.filter-cont {
  margin-bottom: 40px;
}

.filter-floor-badge {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
}

/* Section Card */

.mb-section-card {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 0;
}

.mb-section-card-title {
  font-family: "Helvetic Neue Bold";
  font-weight: bold;
  margin-bottom: 0;
  padding-left: 15px;
  padding-top: 20px;
  color: #5A5A5A;
}

.mb-section-card-item {
  font-size: 14px;
  color: #5A5A5A;
}

.proyect-desc {
  color: #5a5a5a;
  font-family: "Roboto", sans-serif;
}

.mb-btn-text {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  font-size: 12px;
}

.mb-datos-title {
  color: #5A5A5A;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.mb-datos-num {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
  color: #888888;
}

.mb-datos-text {
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.mb-info-title {
  color: #5A5A5A;
  font-family: "Helvetic Neue Bold";
  font-weight: bolder;
}

.mb-info-rating-title {
  color: #5A5A5A;
  font-family: "Helvetica";
  font-weight: bolder;
  font-size: 30px;
}

.mb-info-rating-pretitle {
  font-size: 18px;
  font-weight: normal;
}

.mb-info-rating-cont {
  font-size: 20px;
}

.df-item-mb {
  margin-bottom: 35px;
}

.df-title-mb {
  margin-bottom: 35px;
}

.mini-nvbtn-mb-lg {
  margin-bottom: 15px;
}

.mini-nvbtn-mb {
  margin-bottom: 15px;
}

.mb-section-card p {
  font-size: 16px;
}

.mb-section-card h4 {
  font-family: "Helvetic Neue Bold";
  font-weight: bold;
}

.mb-pf-rating-align {
  text-align: center;
}

.mb-main-logo {
  margin-left: auto;
  margin-right: auto;
}

.mb-hr-indicator {
  border-top: 5px solid #D0D0D0;
}

.mb-hr-indicator-green {
  border-top: 5px solid #5F9B68;
}

.mb-hr-indicator-pink {
  border-top: 5px solid $main-color;
}

.mb-hr-indicator-red {
  border-top: 5px solid #E63721;
}

.mini-card-img {
  width: 100%;
  height: auto;
}

.mb-contact-from-input {
  border-color: #D0D0D0;
}

#datepicker-inline {
  margin-left: auto;
  margin-right: auto;
}

.circle-cont-icon {
  background-color: $main-color;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.circle-icon {
  margin-left: auto;
  margin-right: auto;
}

/* Comp List */

.cl-floor-img {
  height: 200px;
  width: auto;
  display: block;
  margin: auto;
}

.cl-placeholder-ts,
.cl-info-cont-lg {
  display: none;
}

/* Responsive Rules */

@media (min-width: 576px) {
  .onlyTablet {
    display: inline;
  }

  .onlyMobile {
    display: none;
  }

  /* Currency Bar */

  .l-currency-Bar {
    width: 40%;
  }

  .r-currency-Bar {
    width: 60%;
    height: auto;
  }

  .hide-mobile {
    display: inline-block;
  }

  .rcbar-last {
    border-left: 1px solid #62A4C0;
  }

  /* Main Carrousel */

  .slider-rpanel {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .slider-rcardpanel {
    width: 95%;
    padding: 1rem 1rem;
  }

  .nav-link-text {
    font-size: 20px;
    color: white;
  }
}

@media (min-width: 768px) {
  /* Main Carrousel */

  .slider-lpanel {
    text-align: left;
    margin-bottom: 0;
  }

  .onlyDesk {
    display: none;
  }

  .onlyTablet {
    display: inline;
  }

  /* Sectioins */

  .section-lpanel {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .section-rpanel {
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .hide-slide {
    display: inline-block;
  }

  .panel-icon {
    height: 250px;
  }

  .pir0 {
    padding-right: 0;
  }

  .pi-size {
    font-size: 50px;
  }

  .vi-icon {
    display: inline-block;
    justify-content: flex-end;
    align-items: center;
  }

  .vi-icon img {
    width: 120%;
  }

  .vi-main {
    background-image: url(/storage/imgs/otros/home-ventajas.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    height: 430px;
    border-radius: 6px;
    margin-bottom: 30px;
  }

  .vi-body {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .main-from-row label {
    font-size: 20px;
  }

  .main-form-btn {
    width: 35%;
  }

  /* Left Bar */

  .leftbar-cont {
    padding-top: 75px;
  }

  .rightpanel {
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 2%;
    padding-right: 3%;
  }

  .rightpanel .page-display {
    margin-top: 0;
    text-align: right;
  }

  .minibar-filter-btn-hidden-sm {
    display: inline-block;
  }

  .minibar-toggle-btn-hidden-lg {
    display: none;
  }

  .banner-lpanel {
    margin-bottom: 100px;
  }

  .banner-lpanel p,
  .banner-lpanel h1 {
    color: white;
  }

  .banner-rpanel {
    text-align: right;
    margin-bottom: 100px;
  }

  .df-item-mb {
    margin-bottom: 0;
  }

  .mini-nvbtn-mb-lg {
    margin-bottom: 0;
  }

  .mb-char-aleft {
    text-align: left;
  }

  .mb-char-acenter {
    text-align: center;
  }

  .mb-char-aright {
    text-align: right;
  }

  .mb-pf-rating-align {
    text-align: right;
  }

  .footer p{
    width: 100% !important;
    padding-right: 0px !important;
  }

  .nav-link-text {
    font-size: 12px;
    color: white;
  }

  .nbtis {
    display: none;
  }
}

@media (min-width: 992px) {
  /* NAV BAR */

  .nbtis {
    display: none;
  }

  .nbnlg-btn {
    display: inline-block;
  }

  /* .nav-bar-logo {
    height: 30px;
  } */

  /* Currency Bar */

  .l-currency-Bar {
    width: 35%;
  }

  .r-currency-Bar {
    width: 75%;
  }

  .hide-tablet {
    display: inline-block;
  }

  /* Main Carrousel */

  .slider-rpanel {
    justify-content: flex-end;
  }

  .slider-rcardpanel {
    width: 90%;
    margin-top: 39px;
    margin-left: 60px;
  }

  .carrousel-smr {
    margin-right: 90px;
  }
}

@media (min-width: 1013px) {
  .onlyDesk {
    display: inline;
  }

  .onlyTablet {
    display: none;
  }

  .onlyTabletNmobile {
    display: none;
  }

  /* Sectioins */

  .ventajas-item {
    margin-bottom: 25px;
  }

  .ventajas-item h6 {
    font-size: 16px;
  }

  .ventajas-item p {
    font-size: 14px;
  }

  .emb {
    padding-left: 150px;
    padding-right: 150px;
  }

  .main-from-row {
    margin-bottom: 20px;
  }

  .main-form-btn {
    width: 15%;
  }

  .minibar-wrapper {
    margin-left: 25px;
  }

  .display-wrapper {
    margin-left: -25px;
  }

  .item-main-grid-img2 {
    height: 300px;
    width: auto;
  }

  .mainbody {
    margin-left: 0px;
    margin-bottom: 40px;
  }

  .go-right {
    text-align: right;
  }

  .star-rating {
    font-size: 20px;
  }

  .star-rating-empty {
    font-size: 20px;
  }

  /* Comp List text */

  .cl-placeholder-ts {
    display: block;
  }

  .cl-info-cont-lg {
    display: flex;
  }

  .cl-info-cont {
    display: none;
  }
}

.contactanos{
 margin-top: 6px;
 display: block;
}
.navfooter{
  color: $fourth-color;
  text-decoration: none!important;
}
.navfooter:hover {
  color: $main-color;
  text-decoration: none!important;
}
.menufooter{
  display: block;
  margin-right: 50px;
  margin-top: 6px;
}
.footerlogo{
  margin-right: 90px;
}
.textoInvertir{
  width: 44%;
}
.ImagenInvertir{
  width: 44%;
  margin-left: -7%;
}
.section-financiamiento{
  margin-top: -19%;
}
.nuestroslogros{
  margin-top: 5%;
}
.main{
  margin-top: 9%;
}
.nav-fixed{
  position: absolute;
  width: 100%;
  top:0;
  z-index: 999;

}
.nav-fixed-anuncio{
  position: fixed;
  width: 100%;
  top: 92px!important;
  z-index: 990;

}
.avisos{
  border: 1px solid #dee2e6;
  background: white;
}
.link-error:hover{
  text-decoration: none;
  color: #BCD7D1;
}
.link-error{
  color:#BCD7D1;
  font-size: 17px;
  font-family: "helvetica";
  text-decoration: none;
}
#slider-distance{
  width: 340px;
}
.img-galery{
  width: 70px!important;
  height: 70px!important;
}
.title-zonapropia{
  font-family: "Helvetica";
}
.text-nuestroslogros{
  font-size: 13px;
  font-weight: bold;
}
.card-financiamiento-section{
  margin-top: -43%;
}
.img-char{
  width: 1065px;
}

.text-link{
  float: right;
}

.badge-zp-pink {
  color: #fff;
  background-color: $main-color;
}

.badge-zp-yellow {
  color: #fff;
  background-color: #ffbc00;
}

.badge-zp-green {
  color: #fff;
  background-color: #bcd7d1;
}

.mas-proyectos{
  display: none;
}
.paginator{
  display: block;
}
.nav-link-text {
  display:inline-block;
}
.nav-link-text:after {
  display:block;
  content: '';
  border-bottom: solid 1px $main-color;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.nav-link-text:hover:after { transform: scaleX(1); }
.nav-link-text:after{  transform-origin:  0% 50%; }
.colorGreen{
  color: #BCD7D1;
}
@media (min-width: 320px) and (max-width: 480px) {
 .avisos{
   display: none;
 }
 .text-light{
   text-align: left!important;
 }
 .main {
  margin-top: 29%;
}
.mas-proyectos{
  display: block;
}
  /* CSS */
  .text-nuestroslogros,.card-text-alt{
text-align: justify;
  }
.text-nuestroslogros{
  padding-left: 35px!important;
  padding-right: 15px!important;
}
.card-header{
  text-align: justify;
}
.icono-check{
  font-size: 4em!important;
}
.menufooter,.contactanos{
  display: none;
}
.footer p{
  font-size: 15px;
    width: 100%;
    text-align: justify;
    padding-right: 10px;
}
.footerlogo{
  margin-right: 15px;
}
.img-error{
  width: 100%;
}
.nbtis{
  color: $main-color!important;
  font-size: 30px!important;
}
.newProfileBtn {
  display: none;
}
#navbarSupportedContent{
  margin-top: 10%;
  text-align: left;



}
#navbarSupportedContent li{
  margin-right: 0!important;
  border-bottom: 1px solid #5a5a5a;
}
.nav-link-text{
  font-weight: normal;
  font-size: 20px;
  text-decoration: none!important;
}
.img-menu{

  display: block!important;
  width: 100%;
  margin-top: 51px;
  margin-bottom: 30px;

}
.nav-link-text:after {
  display:block;
  content: '';
  border-bottom: solid 0px $main-color;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.nav-link-text:hover:after { transform: scaleX(1); }
.nav-link-text:after{  transform-origin:  0% 50%; }
}
.badge-detalle{
  float: none;
  vertical-align: text-bottom;
}
  